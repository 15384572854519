.container {
    width: 100%;
    margin: 1020px;
  }
  
  .main-image {
    display: block;
    margin: auto;
  }
  
  .main-container {
    width: 50%;
    margin: auto;
  }
  
  .contact-info {
    width: 90%;
    margin: auto;
  }
  
  .contact-info-row a{
    margin: auto;
    text-decoration: none;
  }
  
  .footer {
    background-color: dark;
    border: 1px solid;
  }
  
  .footer p {
    color: white;
    font-size: 14px;
    text-align: center;
    padding: 10px;
  }
  