/* PrivacyPolicy.css */

.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.title {
    color: #333;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.subtitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.paragraph {
    margin-bottom: 10px;
}

.link {
    color: #007bff;
    text-decoration: none;
}

.list {
    margin-bottom: 10px;
    list-style-type: disc;
    padding-left: 20px;
}

.list-item {
    margin-bottom: 5px;
}
  
.footer {
    background-color: dark;
    border: 1px solid;
  }
  
  .footer p {
    color: white;
    font-size: 14px;
    text-align: center;
    padding: 10px;
  }